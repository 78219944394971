<script lang="ts">
	export let direction: 'left' | 'right' = 'left';
</script>

<div class="inline" class:rotate-180={direction === 'right'} class:rotate-0={direction === 'left'}>
	<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.3685 24L7.36849 16L15.3685 8M26.0352 24L18.0352 16L26.0352 8"
			stroke="currentColor"
			stroke-width="1.33333"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</div>
